<template>
  <b-col
    cols="12"
  >
    <!-- form -->
    <validation-observer ref="registerReceiptForm">
      <b-form
        enctype="multipart/form-data"
        @submit.prevent="validationForm"
      >
        <b-card>
          <b-col>
            <b-row>
              <b-col>
                <b-form-group
                  label-cols-sm="12"
                  content-cols-sm="12"
                  label="¿Subes una factura en XML?"
                  label-for="useXML"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Validar si subes un XML o no"
                    rules="required"
                    vid="useXML"
                  >
                    <b-form-radio-group
                      id="useXML"
                      v-model="useXML"
                      class="my-1"
                      :options="optionsXML"
                      value-field="value"
                      name="useXML"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="useXML==='1'"
              class="justify-content-center"
            >
              <!-- xml -->
              <b-col
                md="6"
                class="mb-2"
              >
                <b-form-group
                  label="Subir factura electrónica (XML)"
                  label-for="xml"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Factura electrónica"
                    vid="xml"
                  >
                    <b-input-group>
                      <b-form-file
                        id="xml"
                        v-model="xml"
                        :state="errors.length > 0 ? false:null"
                        name="xml"
                        accept=".xml"
                        placeholder="Ningún archivo elegido"
                        browse-text="Cargar archivo"
                        @change="onXMLChange"
                      />
                      <b-input-group-append>
                        <b-button
                          variant="secondary"
                          size="sm"
                          type="button"
                          @click="uploadXML"
                        >
                          Cargar XML
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-row
                      class="my-1"
                    >
                      <span
                        class="text-center my-1"
                      >
                        (XML registrado anteriormente)
                      </span>
                      <br>
                      <a
                        class="text-info"
                        target="_blank"
                        :href="xmlUrl"
                      >
                        {{ xmlUrl }}
                      </a>
                    </b-row>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <!-- payingCompany -->
              <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  label="Selecciona la empresa pagadora*"
                  label-for="payingCompany"
                >
                  <b-form-select
                    id="payingCompany"
                    v-model="payingCompany"
                    :options="optionsPayingCompanies"
                    required
                    name="payingCompany"
                    :disabled="payingCompany!=='' && useXML==='1'"
                  />
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Empresa pagadora"
                    rules="required"
                    vid="payingCompany"
                  >
                    <b-form-select
                      id="payingCompany"
                      v-model="payingCompany"
                      :options="optionsPayingCompanies"
                      :state="errors.length > 0 ? false:null"
                      name="payingCompany"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <!-- availableBalance -->
              <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  label="Saldo disponible"
                  label-for="availableBalance"
                >
                  <b-input-group
                    :prepend="payerCurrency"
                  >
                    <b-form-input
                      id="availableBalance"
                      v-model="availableBalance"
                      name="availableBalance"
                      readonly
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <!-- ruc -->
              <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  label="RUC"
                  label-for="ruc"
                >
                  <b-form-input
                    id="ruc"
                    v-model="ruc"
                    name="ruc"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <!-- documentType -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Selecciona tipo de documento*"
                  label-for="documentType"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Tipo de documento"
                    rules="required"
                    vid="documentType"
                  >
                    <b-form-select
                      id="documentType"
                      v-model="documentType"
                      :options="useXML==='0' ? optionsDocumentType : optionsDocumentTypeXML"
                      :state="errors.length > 0 ? false:null"
                      name="documentType"
                      :disabled="documentType!=='' && useXML==='1'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- documentNumber -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="N° del documento*"
                  label-for="documentNumber"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Documento"
                    rules="required"
                    vid="documentNumber"
                  >
                    <b-form-input
                      id="documentNumber"
                      v-model="documentNumber"
                      :state="errors.length > 0 ? false:null"
                      name="documentNumber"
                      :readonly="documentNumber!=='' && useXML==='1'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- payingDate -->
              <!-- <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  label="Fecha de pago*"
                  label-for="payingDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha de pago"
                    rules="required"
                    vid="payingDate"
                  >
                    <b-form-datepicker
                      id="payingDate"
                      v-model="payingDate"
                      placeholder="dd/mm/aaaa"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="es"
                      name="payingDate"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <b-form-input
                      v-show="false"
                      v-model="receptionDate"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col> -->
              <!-- currency -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Selecciona la moneda*"
                  label-for="currency"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Moneda"
                    rules="required"
                    vid="currency"
                  >
                    <b-form-select
                      id="currency"
                      v-model="currency"
                      :options="optionsCurrency"
                      :state="errors.length > 0 ? false:null"
                      name="currency"
                      :disabled="currency!=='' && useXML==='1'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- payingAmount -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Importe original de la factura"
                  label-for="payingAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Importe original de la factura"
                    rules="required|money"
                    vid="payingAmount"
                  >
                    <b-form-input
                      id="payingAmount"
                      v-model="payingAmount"
                      :state="errors.length > 0 ? false:null"
                      name="payingAmount"
                      :formatter="formatterMoney"
                      :readonly="payingAmount!=='' && useXML==='1'"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- originalAmount -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Importe neto a pagar"
                  label-for="originalAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Importe neto a pagar"
                    :rules="`required|isMaxVal:${payingAmount}`"
                    vid="originalAmount"
                  >
                    <b-form-input
                      id="originalAmount"
                      v-model="originalAmount"
                      type="number"
                      step="0.01"
                      :max="payingAmount"
                      :state="errors.length > 0 ? false:null"
                      name="originalAmount"
                      :readonly="payingAmount===''"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- receptionDate -->
              <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  label="Fecha de recepción*"
                  label-for="receptionDate"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Fecha de recepción"
                    rules="required"
                    vid="receptionDate"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="'Fecha en la que el pagador recepcionó la factura'"
                          variant="outline-primary"
                          class="btn-icon"
                        >
                          <feather-icon
                            size="15"
                            icon="InfoIcon"
                          />
                        </b-button>
                      </b-input-group-prepend>
                      <b-form-datepicker
                        id="receptionDate"
                        v-model="receptionDate"
                        placeholder="dd/mm/aaaa"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        locale="es"
                        :max="today"
                        name="receptionDate"
                        :readonly="receptionDate!=='' && useXML==='1'"
                        :state="errors.length > 0 ? false:null"
                        required
                      />
                      <b-form-input
                        v-show="false"
                        v-model="receptionDate"
                        :state="errors.length > 0 ? false:null"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- payingTerm -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Selecciona el plazo"
                  label-for="payingTerm"
                >
                  <b-form-input
                    id="payingTerm"
                    v-model="payingTerm"
                    name="payingTerm"
                    readonly=""
                  />
                </b-form-group>
              </b-col>
              <!-- duesQuantity -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Selecciona la cantidad de cuotas"
                  label-for="duesQuantity"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cuotas"
                    rules="required"
                    vid="duesQuantity"
                  >
                    <b-form-select
                      id="duesQuantity"
                      v-model="duesQuantity"
                      :options="optionsDuesQuantity"
                      :state="errors.length > 0 ? false:null"
                      name="duesQuantity"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-for="(values, index) in payingValues"
              :key="index"
            >
              <!-- payingDate -->
              <b-col
                xl="4"
                lg="6"
              >
                <b-form-group
                  :label="`Fecha de pago ${index + 1}`"
                  :label-for="`payingDates${index}`"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="`Fecha de pago ${index + 1}`"
                    rules="required"
                    :vid="`payingDates${index}`"
                  >
                    <b-form-datepicker
                      v-if="isNewVersion && paymentType === 'Credito'"
                      :id="`payingDates${index}`"
                      v-model="values.payingDate"
                      placeholder="dd/mm/aaaa"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="es"
                      :min="duesData[index].dueDate"
                      :max="payingValues[index+1] !== undefined ? payingValues[index+1].payingDate : ''"
                      :name="`payingDates${index}`"
                      :disabled="( index !== 0 && payingValues[index-1] !== undefined && payingValues[index-1].payingDate === '')"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <b-form-datepicker
                      v-else
                      :id="`payingDates${index}`"
                      v-model="values.payingDate"
                      placeholder="dd/mm/aaaa"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      locale="es"
                      :min="index === 0 ? today : ( payingValues[index-1].payingDate === '' ? today : payingValues[index-1].payingDate)"
                      :max="payingValues[index+1] !== undefined ? payingValues[index+1].payingDate : ''"
                      :name="`payingDates${index}`"
                      :disabled="( index !== 0 && payingValues[index-1] !== undefined && payingValues[index-1].payingDate === '')"
                      :state="errors.length > 0 ? false:null"
                      required
                    />
                    <b-form-input
                      v-show="false"
                      v-model="values.payingDate"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- payingAmount -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  :label="`Importe de pago ${index + 1}`"
                  label-for="firstPayingAmount"
                >
                  <validation-provider
                    v-if="isNewVersion && paymentType === 'Credito'"
                    #default="{ errors }"
                    :name="`Importe de pago ${index + 1}`"
                    :rules="`required|money|isMaxVal:${duesData[index].amount}`"
                    :vid="`payingDates${index}`"
                  >
                    <b-form-input
                      :id="`payingDates${index}`"
                      v-model="values.payingAmount"
                      :state="errors.length > 0 ? false:null"
                      :name="`payingDates${index}`"
                      :formatter="formatterMoney"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                  <validation-provider
                    v-else
                    #default="{ errors }"
                    :name="`Importe de pago ${index + 1}`"
                    rules="required|money"
                    :vid="`payingDates${index}`"
                  >
                    <b-form-input
                      :id="`payingDates${index}`"
                      v-model="values.payingAmount"
                      :state="errors.length > 0 ? false:null"
                      :name="`payingDates${index}`"
                      :formatter="formatterMoney"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <!-- observation -->
              <b-col
                lg="8"
                xl="8"
              >
                <b-form-group
                  label="Observación"
                  label-for="observation"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Observación"
                    vid="observation"
                  >
                    <b-form-textarea
                      id="observation"
                      v-model="observation"
                      :state="errors.length > 0 ? false:null"
                      name="observation"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row
              v-if="useXML==='0'"
            >
              <!-- frontalImage -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Subir imagen del comprobante (Frontal)"
                  label-for="frontalImage"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Imagen frontal"
                    vid="frontalImage"
                  >
                    <b-form-file
                      id="frontalImage"
                      v-model="frontalImage"
                      :state="errors.length > 0 ? false:null"
                      name="frontalImage"
                      accept="image/*"
                      placeholder="Ningún archivo elegido"
                      browse-text="Cargar archivo"
                      @change="onFrontalImageChange"
                    />
                    <a
                      class="text-info"
                      target="_blank"
                      :href="frontalImgUrl"
                    >
                      {{ frontalImgUrl }}
                    </a>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- backImage -->
              <b-col
                lg="6"
                xl="4"
              >
                <b-form-group
                  label="Subir imagen del comprobante (Reverso)"
                  label-for="backImage"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Imagen posterior"
                    vid="backImage"
                  >
                    <b-form-file
                      id="backImage"
                      v-model="backImage"
                      :state="errors.length > 0 ? false:null"
                      name="backImage"
                      accept="image/*"
                      placeholder="Ningún archivo elegido"
                      browse-text="Cargar archivo"
                      @change="onBackImageChange"
                    />
                    <a
                      class="text-info"
                      target="_blank"
                      :href="backImgUrl"
                    >
                      {{ backImgUrl }}
                    </a>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-card>
        <!-- submit button -->
        <b-row>
          <b-col
            sm="6"
            md="3"
          >
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              block
              @click="cancelRegisterReceipt"
            >
              Volver
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BFormDatepicker, BButton, BRow, BCol, BFormSelect, BFormTextarea, BFormFile, VBTooltip, BInputGroupPrepend, BInputGroupAppend, BInputGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormSelect,
    BFormTextarea,
    BFormFile,
    BFormRadioGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    return {
      dataLoaded: false,
      today,
      required,
      userRuc: JSON.parse(localStorage.getItem('userData')).ruc,
      userEmail: JSON.parse(localStorage.getItem('userData')).email,
      xml: null,
      payingCompany: '',
      availableBalance: '',
      ruc: '',
      documentType: '',
      documentNumber: '',
      receptionDate: '',
      payingDate: '',
      currency: '',
      payingAmount: '',
      payingTerm: 0,
      duesQuantity: '',
      originalAmount: '',
      observation: '',
      frontalImage: null,
      backImage: null,
      useXML: '0',
      payingValues: [],
      payers: [],
      payerCurrency: '',
      idPayer: '',
      idReceipt: '',
      receipt: null,
      frontalImgUrl: '',
      backImgUrl: '',
      xmlUrl: '',
      previousDocumentNumber: '',
      // NEW VERSION
      paymentType: '',
      isNewVersion: false,
      duesData: [],
      // Options
      optionsXML: [
        { value: '0', text: 'No' },
        { value: '1', text: 'Si' },
      ],
      optionsPayingCompanies: [
        { value: null, text: 'Por favor seleccionar una empresa pagadora', disabled: true },
      ],
      optionsDuesQuantity: [
        { value: null, text: 'Por favor seleccionar una frecuencia', disabled: true },
        { value: '1', text: 'Al vencimiento' },
        { value: '2', text: '2 Cuotas' },
        { value: '3', text: '3 Cuotas' },
        { value: '4', text: '4 Cuotas' },
        { value: '5', text: '5 Cuotas' },
        { value: '6', text: '6 Cuotas' },
        { value: '7', text: '7 Cuotas' },
        { value: '8', text: '8 Cuotas' },
        { value: '9', text: '9 Cuotas' },
        { value: '10', text: '10 Cuotas' },
      ],
      optionsCollectPeriod: [
        { value: null, text: 'Por favor seleccionar un periodo', disabled: true },
        { value: '30', text: '30 días' },
        { value: '60', text: '60 días' },
        { value: '90', text: '90 días' },
        { value: '120', text: '120 días' },
        { value: '150', text: '150 días' },
        { value: '180', text: '180 días' },
      ],
      optionsCurrency: [
        { value: null, text: 'Por favor seleccionar una moneda', disabled: true },
        { value: 'Soles', text: 'Soles (S/)' },
        { value: 'Dolares', text: 'Dólares ($)' },
      ],
      optionsDocumentType: [
        { value: null, text: 'Por favor seleccionar un tipo de documento', disabled: true },
        { value: 'Factura negociable', text: 'Factura negociable' },
        { value: 'Letra de cambio', text: 'Letra de cambio' },
      ],
      optionsDocumentTypeXML: [
        {
          value: 'Factura electronica', text: 'Factura electrónica',
        },
        {
          value: 'Recibo por Honorarios Electrónico', text: 'Recibo por Honorarios Electrónico',
        },
      ],
    }
  },
  watch: {
    payingValues: {
      handler() {
        let val = 0
        for (let i = 0; i < this.payingValues.length; i += 1) {
          const days = Math.ceil((new Date(this.payingValues[i].payingDate) - new Date()) / (1000 * 3600 * 24))
          if (days > val) {
            val = days
          }
        }
        this.payingTerm = val
      },
      deep: true,
    },
    useXML() {
      if (this.useXML === '1') {
        this.frontalImage = null
        this.backImage = null
        this.xml = null
      } else {
        this.frontalImage = null
        this.backImage = null
        this.xml = null
      }
    },
    duesQuantity() {
      if (this.paymentType !== 'Credito') {
        if (parseInt(this.duesQuantity, 10) > 0 && this.dataLoaded) {
          this.payingValues = []
          for (let index = 0; index < parseInt(this.duesQuantity, 10); index += 1) {
            this.payingValues.push(
              {
                payingDate: '',
                payingAmount: '',
              },
            )
          }
        }
      }
    },
    /* payingAmount() {
      this.originalAmount = (parseFloat(this.payingAmount) * 1.18).toFixed(2)
    }, */
    payingCompany() {
      for (let index = 0; index < this.payers.length; index += 1) {
        if (this.payers[index]._id === this.payingCompany) {
          this.ruc = this.payers[index].ruc
          switch (this.payers[index].approveCurrency) {
            case 'Soles':
              this.payerCurrency = 'S/'
              break
            case 'Dolares':
              this.payerCurrency = '$'
              break
            default:
              this.payerCurrency = ''
              break
          }
          this.availableBalance = this.payers[index].disponibleAmount
        }
      }
    },
  },
  created() {
    const receiptId = this.$route.params.id
    useJwt.getReceiptDetailClient({
      ruc: this.userRuc,
      email: this.userEmail,
      receiptId,
    }).then(res => {
      this.receipt = res.data.receipt
      this.payingCompany = this.receipt.payer._id
      this.ruc = this.receipt.payer.ruc
      switch (this.receipt.payer.approveCurrency) {
        case 'Soles':
          this.payerCurrency = 'S/'
          break
        case 'Dolares':
          this.payerCurrency = '$'
          break
        default:
          this.payerCurrency = ''
          break
      }
      this.idPayer = this.receipt.efideData.IdPagadora // ID PAGADORA EFIDE
      this.idReceipt = this.receipt.efideData.IdPlanilla // ID FACTURA EFIDE
      this.availableBalance = this.receipt.payer.disponibleAmount
      this.documentType = this.receipt.documentType
      this.previousDocumentNumber = this.receipt.documentNumber
      this.documentNumber = this.receipt.documentNumber
      this.receptionDate = this.receipt.receptionDate
      this.payingDate = this.receipt.payingDate
      this.currency = this.receipt.currency
      this.payingAmount = this.receipt.payingAmount
      this.payingTerm = this.receipt.payingTerm
      this.duesQuantity = this.receipt.duesQuantity
      this.originalAmount = this.receipt.originalAmount
      this.observation = this.receipt.observation
      this.frontalImgUrl = this.receipt.frontalImageUrl
      this.backImgUrl = this.receipt.backImageUrl
      this.xmlUrl = this.receipt.xmlUrl
      for (let i = 0; i < this.receipt.payingValues.length; i += 1) {
        this.payingValues.push({
          payingAmount: this.receipt.payingValues[i].payingAmount,
          payingDate: this.receipt.payingValues[i].payingDate,
        })
      }
      if (this.receipt.xmlUrl) {
        this.useXML = '1'
      }
      this.$nextTick(() => {
        this.dataLoaded = true
      })
    })
    useJwt.getMyPayers({
      ruc: this.userRuc,
      email: this.userEmail,
    }).then(res => {
      this.payers = res.data.payers
      for (let index = 0; index < this.payers.length; index += 1) {
        const value = this.payers[index]._id
        const text = this.payers[index].businessName
        const { status } = this.payers[index]
        if (status === 'approved') {
          this.optionsPayingCompanies.push({ value, text })
        }
      }
    })
  },
  methods: {
    resetValues() {
      this.duesQuantity = 0
      this.payingValues = []
      this.paymentType = ''
      this.duesData = []
    },
    resetAllValues() {
      this.payingCompany = ''
      this.availableBalance = ''
      this.ruc = ''
      this.documentType = ''
      this.documentNumber = ''
      this.receptionDate = ''
      this.payingDate = ''
      this.currency = ''
      this.payingAmount = ''
      this.payingTerm = 0
      this.duesQuantity = ''
      this.originalAmount = ''
      this.payingValues = []
      this.paymentType = ''
      this.duesData = []
      this.isNewVersion = false
    },
    uploadXML() {
      const formData = new FormData()
      formData.append('xml', this.xml)
      useJwt.uploadXMLToGetData(formData)
        .then(({ data }) => {
          this.resetValues()
          let exists = false
          for (let index = 0; index < this.payers.length; index += 1) {
            if (this.payers[index].ruc === data.receiptInfo.ruc) {
              this.payingCompany = this.payers[index]._id
              this.idPayer = this.payers[index].efideData ? this.payers[index].efideData.IdPagador : ''
              exists = true
              break
            }
          }
          if (exists) {
            this.currency = data.receiptInfo.currency
            this.payingAmount = data.receiptInfo.payingAmount
            this.documentNumber = data.receiptInfo.documentNumber
            this.payingDate = data.receiptInfo.payingDate
            this.receptionDate = data.receiptInfo.receptionDate
            // NEW VERSION
            this.duesQuantity = data.receiptInfo.duesData.length > 0 ? data.receiptInfo.duesData.length : 1
            this.paymentType = data.receiptInfo.paymentType
            this.duesData = data.receiptInfo.duesData
            if (this.paymentType === 'Credito') {
              for (let i = 0; i < this.duesData.length; i += 1) {
                this.payingValues.push(
                  {
                    payingDate: this.duesData[i].dueDate,
                    payingAmount: this.duesData[i].amount,
                  },
                )
              }
            }
            this.isNewVersion = (new Date(this.receptionDate) > new Date('2021-12-17 12:00:00'))
            // console.log(this.duesData)
            this.documentType = data.receiptInfo.documentType
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Éxito',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Se ha cargado la información con éxito',
                autoHideDelay: 5000,
              },
            })
          } else {
            this.resetAllValues()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'No existe la empresa pagadora',
                autoHideDelay: 5000,
              },
            })
          }
        })
        .catch(error => {
          console.log('errores', error.response.data.error)
          // eslint-disable-next-line
          const textError = error.response.data.error === "Cannot destructure property 'buffer' of 'data' as it is undefined." ? 'Debe subir un XML para cargar sus datos' :  error.response.data.error
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: textError,
              autoHideDelay: 5000,
            },
          })
        })
    },
    cancelRegisterReceipt() {
      this.$swal({
        title: '¡Advertencia!',
        text: 'Se perderán todos los datos ingresados.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.replace('/cliente/lista-solicitudes-por-enviar')
          }
        })
    },
    onXMLChange(e) {
      const selectedFile = e.target.files[0]
      this.xml = selectedFile
    },
    onFrontalImageChange(e) {
      const selectedFile = e.target.files[0]
      this.frontalImage = selectedFile
    },
    onBackImageChange(e) {
      const selectedFile = e.target.files[0]
      this.backImage = selectedFile
    },
    formatterNumber(value) {
      return value.replace(/[^0-9]/g, '')
    },
    formatterMoney(value) {
      return value.replace(/[^0-9.]/g, '')
    },
    formatterTel(value) {
      return value.replace(/[^0-9()+]/g, '')
    },
    preciseSum(sum, num) {
      let precision = 0
      if (num.toString().includes('.') && Math.floor(num) !== num) {
        precision = num.toString().split('.')[1].length || 0
      }
      const factor = 10 ** precision
      return parseFloat(num).toString() === (Math.round(sum * factor) / factor).toString()
    },
    validationForm() {
      let sum = 0
      for (let index = 0; index < this.payingValues.length; index += 1) {
        sum += parseFloat(this.payingValues[index].payingAmount)
      }

      this.payingDate = this.payingValues[this.payingValues.length - 1].payingDate

      if (!this.preciseSum(sum, this.originalAmount)) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'DatabaseIcon',
            variant: 'danger',
            text: 'La suma de los importes de pago de las cuotas debe ser igual al importe neto a pagar',
          },
        },
        {
          timeout: 8000,
        })
      } else {
        this.$refs.registerReceiptForm.validate().then(success => {
          if (success) {
            const formData = new FormData()
            formData.append('receiptId', this.receipt._id)
            formData.append('email', this.userEmail)
            formData.append('ruc', this.userRuc)
            formData.append('payerId', this.payingCompany)
            formData.append('payerIdEfide', this.idPayer)
            formData.append('receiptIdEfide', this.idReceipt)
            formData.append('useXML', this.useXML)
            formData.append('xml', this.xml)
            formData.append('documentType', this.documentType)
            formData.append('documentNumber', this.documentNumber)
            formData.append('previousDocumentNumber', this.previousDocumentNumber)
            formData.append('receptionDate', this.receptionDate)
            formData.append('payingDate', this.payingDate)
            formData.append('currency', this.currency)
            formData.append('payingAmount', this.payingAmount)
            formData.append('payingTerm', this.payingTerm)
            formData.append('duesQuantity', this.duesQuantity)
            formData.append('originalAmount', this.originalAmount)
            formData.append('observation', this.observation)
            formData.append('frontalImage', this.frontalImage)
            formData.append('backImage', this.backImage)
            formData.append('previousBackImage', this.backImgUrl)
            formData.append('previousFrontalImage', this.frontalImgUrl)
            formData.append('previousXML', this.xmlUrl)
            formData.append('payingValues', JSON.stringify(this.payingValues))
            useJwt.postReceiptUpdateClient(formData)
              .then(() => {
                this.$router.replace('/cliente/lista-solicitudes-por-enviar')
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Éxito',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Se ha actualizado el comprobante',
                      },
                    },
                    {
                      timeout: 8000,
                    })
                  })
              })
              .catch(error => {
                if (error.response.data.error.receiptError !== '') {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error al editar solicitud de factoring',
                      icon: 'BellIcon',
                      variant: 'danger',
                      text: error.response.data.error.receiptError,
                    },
                  },
                  {
                    timeout: 8000,
                  })
                }
                console.log('errores', error.response.data.error)
                this.$refs.registerReceiptForm.setErrors(error.response.data.error)
              })
          }
        })
      }
    },
  },
}
</script>
